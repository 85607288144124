import React, { useState } from 'react';

import Layout from '../components/Layout';

import { Helmet } from 'react-helmet';

import OurTeam from '../components/OurTeam';

import ALEKS from '../images/nos-serruriers/image9.webp';
import MOUSSA from '../images/nos-serruriers/image18.webp';
import ELIAS from '../images/nos-serruriers/image19.webp';
import KAIS from '../images/nos-serruriers/image2.webp';

const TEAM = [
  {
    location: 'Genéve',
    teams: [
      {
        name: 'Linda',
        area: 'Serrurier Genève ',
        text: 'Couvre le centre ville de Genève',
      },
      {
        name: 'Kais',
        area: 'Serrurier Pregny-Chambésy',
        image: KAIS,
      },
      {
        name: 'Yanis',
        area: 'Serrurier Carouge',
      },
      {
        name: 'Marc',
        area: 'Serrurier Versoix ',
      },
      {
        name: 'Adam',
        area: 'Serrurier Thônex',
      },
      {
        name: 'Alkan',
        area: 'Serrurier Ferney-Voltaire',
      },
      {
        name: 'Kamil',
        area: 'Serrurier Meyrin',
      },
      {
        name: 'Aleks',
        area: 'Serrurier Lancy ',
        image: ALEKS,
      },
      {
        name: 'Amine',
        area: 'Serrurier Veyrier',
      },
      {
        name: 'Riad',
        area: 'Serrurier Onex',
      },
      {
        name: 'Moussa',
        area: 'Serrurier Cartigny',
        image: MOUSSA,
      },
      {
        name: 'Nicolas',
        area: 'Serrurier Cologny',
      },
      {
        name: 'Yohan',
        area: 'Serrurier Collonge-Bellerive',
      },
      {
        name: 'Elias',
        area: 'Serrurier Jussy',
        image: ELIAS,
      },
      {
        name: 'Armand',
        area: 'Serrurier Choulex ',
      },
      {
        name: 'Volkan',
        area: 'Serrurier Corsier',
      },
    ],
  },
  {
    location: 'Lausanne',
    teams: [
      {
        name: 'Mathieu',
        area: 'Serrurie Lausanne',
        text: 'Lausanne',
      },
      {
        name: 'Kais',
        area: 'Serrurier Pregny-Chambésy',
        image: KAIS,
      },
      {
        name: 'Linda',
        area: 'Serrurier Genève ',
        text: 'Couvre le centre ville de Genève',
      },
      {
        name: 'Yanis',
        area: 'Serrurier Carouge',
      },
      {
        name: 'Marc',
        area: 'Serrurier Versoix ',
      },
      {
        name: 'Adam',
        area: 'Serrurier Thônex',
      },
      {
        name: 'Alkan',
        area: 'Serrurier Ferney-Voltaire',
      },
      {
        name: 'Kamil',
        area: 'Serrurier Meyrin',
      },
      {
        name: 'Aleks',
        area: 'Serrurier Lancy ',
        image: ALEKS,
      },
      {
        name: 'Amine',
        area: 'Serrurier Veyrier',
      },
      {
        name: 'Riad',
        area: 'Serrurier Onex',
      },
      {
        name: 'Moussa',
        area: 'Serrurier Cartigny',
        image: MOUSSA,
      },
      {
        name: 'Nicolas',
        area: 'Serrurier Cologny',
      },
      {
        name: 'Yohan',
        area: 'Serrurier Collonge-Bellerive',
      },
      {
        name: 'Elias',
        area: 'Serrurier Jussy',
        image: ELIAS,
      },
      {
        name: 'Armand',
        area: 'Serrurier Choulex ',
      },
      {
        name: 'Volkan',
        area: 'Serrurier Corsier',
      },
    ],
  },
  {
    location: 'Montreux',
    teams: [
      {
        name: 'Maurice',
        area: 'Serrurier Montreux ',
      },
      {
        name: 'Kais',
        area: 'Serrurier Pregny-Chambésy',
        image: KAIS,
      },
      {
        name: 'Yanis',
        area: 'Serrurier Carouge',
      },
      {
        name: 'Marc',
        area: 'Serrurier Versoix ',
      },
      {
        name: 'Adam',
        area: 'Serrurier Thônex',
      },
      {
        name: 'Alkan',
        area: 'Serrurier Ferney-Voltaire',
      },
      {
        name: 'Kamil',
        area: 'Serrurier Meyrin',
      },
      {
        name: 'Aleks',
        area: 'Serrurier Lancy ',
        image: ALEKS,
      },
      {
        name: 'Amine',
        area: 'Serrurier Veyrier',
      },
      {
        name: 'Riad',
        area: 'Serrurier Onex',
      },
      {
        name: 'Moussa',
        area: 'Serrurier Cartigny',
        image: MOUSSA,
      },
      {
        name: 'Nicolas',
        area: 'Serrurier Cologny',
      },
      {
        name: 'Yohan',
        area: 'Serrurier Collonge-Bellerive',
      },
      {
        name: 'Elias',
        area: 'Serrurier Jussy',
        image: ELIAS,
      },
      {
        name: 'Armand',
        area: 'Serrurier Choulex ',
      },
      {
        name: 'Volkan',
        area: 'Serrurier Corsier',
      },
    ],
  },

  {
    location: 'Neuchatel',
    teams: [
      {
        name: 'Martin',
        area: 'Serrurier Neuchâtel',
        text: 'Couvre le centre ville de Neuchâtel',
      },
      {
        name: 'Mehran',
        area: 'Serrurier Peseux',
      },
      {
        name: 'Clara',
        area: 'Serrurier Milvignes',
      },
      {
        name: 'Slimane',
        area: 'Serrurier Rochefort',
      },
      {
        name: 'Ilyes',
        area: 'Serrurier St-Blaise',
      },
      {
        name: 'Sacha',
        area: 'Serrurier Marin-Epagnier',
      },
      {
        name: 'Darko',
        area: 'Serrurier Cornaux',
      },
      {
        name: 'Yassin',
        area: 'Serrurier Fontaines',
      },
      {
        name: 'Alpha',
        area: 'Serrurier La chaux-de-Fonds',
      },
      {
        name: 'Imany',
        area: 'Serrurier Cortaillod',
      },
    ],
  },

  {
    location: 'Fribourg',
    teams: [
      {
        name: 'Eric',
        area: 'Serrurier Fribourg',
        text: 'Couvre le centre-ville de Fribourg',
      },
      {
        name: 'Aymen',
        area: 'Serrurier Villars-sur-Glâne',
      },
      {
        name: 'Sam',
        area: 'Serrurier Guin',
      },
      {
        name: 'Adel',
        area: 'Serrurier Courtepin',
      },
      {
        name: 'Badr',
        area: 'Serrurier Matran',
      },
      {
        name: 'Bastien',
        area: 'Serrurier St-Antoni',
      },
      {
        name: 'Raoul',
        area: 'Serrurier Barberêche',
      },
      {
        name: 'Dylan',
        area: 'Serrurier Marly',
      },
      {
        name: 'Okena',
        area: 'Serrurier Rossens',
      },
    ],
  },
  {
    location: 'Valais',
    teams: [
      {
        name: 'Loris',
        area: 'Serrurier Sion',
        text: 'Couvre Conthey, Nax, Vax…',
      },
      {
        name: 'Tiago',
        area: 'Serrurier Sierre',
        text: 'Couvre Lens, Ayent, Chalais...',
      },
      {
        name: 'Lorenzo',
        area: 'Serrurier Martigny',
        text: 'Couvre Orsières, Fully, Salvan...',
      },
      {
        name: 'Ugo',
        area: 'Serrurier Monthey',
        text: 'Couvre Bex, St-Maurice, Champéry...',
      },
      {
        name: 'Nael',
        area: 'Serrurier Viège',
        text: 'Couvre Raron, Grachen, Brigerbad ...',
      },
      {
        name: 'Adham',
        area: 'Serrurier Brigue-Glis',
        text: 'Couvre Termen, Naters, Ried-Brig...',
      },
    ],
  },
];

const TEAMPAGE = () => {
  const [location, setLoction] = useState(TEAM[0]);
  return (
    <Layout>
      <div className="min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Nos serruriers | Super serrurier</title>
          <meta
            name="description"
            content="Faites connaissance avec notre équipe de serruriers experts à Genève ! Problème de serrure ou de porte ? Notre équipe de serruriers est là pour vous aider. Avec une intervention d'urgence dans les 30 minutes, 24/7 "
          />
        </Helmet>

        <div>
          <div className="mt-12 lg:mt-24 flex justify-center gap-4 flex-wrap">
            {' '}
            {TEAM.map((team) => {
              return (
                <button
                  key={team.location}
                  onClick={() => {
                    setLoction(team);
                  }}
                  className={`text-base font-medium px-5 py-2 rounded-md shadow-sm  border-gray-200 text-white ${
                    team.location === location.location
                      ? 'bg-secondarycolor'
                      : 'bg-secondarycolor-light'
                  }`}
                >
                  {team.location}
                </button>
              );
            })}
          </div>
          <OurTeam team={location.teams} location={location.location} />
        </div>
        <div className="flex justify-center mb-24">
          {' '}
          <a
            id="call-button"
            href="tel:0791304768"
            className="mt-4 inline-flex items-center text-secondarycolor bg-gray-200 rounded-full p-2 sm:mt-5 sm:text-base lg:mt-6 lg:text-sm xl:text-base hover:text-secondarycolor-light"
          >
            <span className="px-3 py-2 text-white text-lg  lg:text-xl xl:text-2xl  leading-5  tracking-wide bg-primarycolor rounded-full">
              Appelez-nous
            </span>
            <span className="  ml-3 text-lg  lg:text-xl xl:text-2xl font-semibold leading-5  ">
              079 130 47 68
            </span>
            <svg
              className="ml-2 w-6 h-6 "
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </Layout>
  );
};
export default TEAMPAGE;
