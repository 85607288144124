import React from 'react';

import ImageKeys from '../images/keys.png';

export default function OurTeam({ team, location }) {
  return (
    <div id="our-team" className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-7xl">
            <h2
              className="text-4xl font-extrabold text-primarycolor"
              style={{ marginBottom: '2rem' }}
            >
              Serruriers de {location}
            </h2>
            {/* <p
              className="text-xl text-gray-500 w-1/3"
              style={{ width: '60%', margin: '0 auto', marginTop: '1rem' }}
            >
              Ornare sagittis, suspendisse in hendrerit quis. Sed dui aliquet
              lectus sit pretium egestas vel mattis neque.
            </p> */}
          </div>
          <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-4 lg:max-w-7xl mt-12">
            {team.map((member) => (
              <li key={member.name}>
                <div className="space-y-6">
                  <img
                    className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                    src={member.image ? member.image : ImageKeys}
                    alt={member.name}
                    height="10rem"
                    width="10rem"
                    loading="lazy"
                  />
                  <div className="space-y-2">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3>{member.name}</h3>
                      <h2 className="text-secondarycolor">{member.area}</h2>
                      <p className="text-gray-600">
                        {member.text ? member.text : ''}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
